let dropdowns = document.querySelectorAll('.dropdown');

dropdowns.forEach(dropdown => {
    dropdown.addEventListener('click', () => {
        dropdown.classList.toggle('active');
    });

    let options = dropdown.querySelectorAll('.option');

    options.forEach(option => {
        option.addEventListener('click', () => {
            dropdown.querySelector('.hidden-value').value = option.dataset.value;
            dropdown.querySelector('.placeholder').innerHTML = option.innerHTML;
        });
    });
});