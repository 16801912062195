let inputs = document.querySelectorAll('.input-text, .input-textarea, .input-file');

inputs.forEach(input => {
    input.addEventListener('mouseenter', () => {
        input.classList.add('hover');
    });
    input.addEventListener('mouseleave', () => {
        input.classList.remove('hover');
    });

    input.querySelector('input,textarea').addEventListener('focus', () => {
        input.classList.add('active');
        let tel = input.querySelector('input');
        if (input.querySelector('input[type="tel"]') && tel.value === '') {
            tel.value = '+370';
        }
    });
    input.querySelector('input, textarea').addEventListener('blur', () => {
        input.classList.remove('active');
        if (input.querySelector('input, textarea').value !== '') {
            input.classList.add('has-value');
        } else {
            input.classList.remove('has-value');
        }
    });
    input.querySelector('input, textarea').addEventListener("change", function () {
        if (input.querySelector('input[type="file"]')) {
            if (input.querySelector('input[type="file"]').files.length > 0) {
                const selectedFile = input.querySelector('input[type="file"]').files[0];
                input.querySelector('span.name').textContent = selectedFile.name;
                if (input.querySelector('span.name').textContent.length !== 0) {
                    input.classList.add('has-value');
                } else {
                    input.classList.remove('has-value');
                }
            }
        }
    })
    input.querySelector('input, textarea').addEventListener('keydown', (event) => {
        let tel = input.querySelector('input');
        if (input.querySelector('input[type="tel"]')) {
            const maxLength = tel.getAttribute('max');
            const currentLength = tel.value.length;

            if (currentLength >= maxLength && event.key !== 'Backspace' && event.key !== 'Delete') {
                event.preventDefault();
            }
        }
    });

});